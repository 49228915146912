<template>
  <b-card title="All Item Inventory Dealer">
    <b-col cols="12" class="mb-2">
      <div class="filter-responsive">
        <div class="input-group">
          <div class="input-group-prepend">
            <b-dropdown
              style="margin: 0 !important;"
              id="dropdown-1"
              :text="selectedFilter"
              class="m-md-2"
            >
              <b-dropdown-item @click="dropDownMenuClick" field="serial"
                >Serial</b-dropdown-item
              >
              <b-dropdown-item @click="dropDownMenuClick" field="barcode"
                >Barcode</b-dropdown-item
              >
              <b-dropdown-item @click="dropDownMenuClick" field="sapDo"
                >DO</b-dropdown-item
              >
              <b-dropdown-item @click="dropDownMenuClick" field="deliveryDate"
                >Delivery Date</b-dropdown-item
              >
            </b-dropdown>
          </div>
          <!-- <b-form-select
            v-model="type"
            v-if="selectedField==='serial'||selectedField==='barcode'"
            :options="typeOptions"
            size="md"
            class="w-5"
          />
          <b-form-select
            v-model="typeDo"
            v-if="selectedField==='sapDo'"
            :options="typeDoOptions"
            size="md"
            class="w-5"
          /> -->
          <b-form-datepicker
            v-if="selectedField == 'deliveryDate'"
            v-model="toFilter"
          />
          <b-form-input
            v-if="selectedField !== 'deliveryDate'"
            :disabled="typeDo === 'Not Set Up' || type !== 'Set Up'"
            v-model="toFilter"
            placeholder="Filter.."
          />
          <b-form-select
            v-model="status"
            v-if="selectedField === 'warrantyStatus'"
            :options="statusOptions"
            size="md"
            class="w-25"
          >
          </b-form-select>
        </div>

        <b-button @click="doFilter" variant="primary">Filter</b-button>
        <b-button @click="clearFilter" variant="primary">Clear</b-button>
      </div>
    </b-col>
    <b-col cols="12" class="mb-2">
      <div class="filter-responsive">
        <b-button
          @click="toBulkPrint()"
          variant="primary"
          :disabled="!canBulkPrint"
          >Bulk Print by DO</b-button
        >
      </div>
    </b-col>

    <b-col cols="12">
      <b-row>
        <b-col cols="12">
          <b-table
            :fields="fields"
            :items="items"
            :filter="search"
            :busy.sync="tableLoading"
            show-empty
            responsive
            table-class="w-auto"
          >
            <template #cell(actions)="row">
              <b-button
                variant="primary"
                @click="printWarrantyInstruction(row.item._id)"
                >Print Warranty Instruction</b-button
              >
            </template>
            <template #cell(sapDo)="row">
              {{ row.item.sapDo === "" ? "-" : row.item.sapDo }}
            </template>

            <template #cell(barcode)="row">
              {{ scanningStatus(row.item).barcode }}
            </template>

            <template #cell(serial)="row">
              {{ scanningStatus(row.item).serial }}
            </template>

            <template #cell(createdAt)="row">
              {{ row.item.createdAt | formatDate }}
            </template>

            <template #cell(warrantyStatus)="row">
              <div v-if="canEdit">
                <b-form-select
                  class="w-100"
                  v-model="row.item.warrantyStatus"
                  placeholder="----- Select Status -----"
                  :options="statusList"
                  @change="
                    (selected) =>
                      updateStatus(row.item._id, selected, row.item.barcode)
                  "
                >
                </b-form-select>
              </div>
              <div v-else>
                {{ row.item.warrantyStatus }}
              </div>
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination
            :total-rows="totalRows"
            :per-page="perPage"
            @change="onPageChange"
            v-model="currentPage"
            style="float: right"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>

<script>
import axios from "@/axios";
import { userAccess } from "@/utils/utils";
import {
  logo,
  footer,
  header,
} from "../../delivery-module/actions-detail/daikin-img-base64";
import { mapActions, mapState, mapGetters } from "vuex";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import pdfMake from "pdfmake/build/pdfmake";

export default {
  data() {
    return {
      daikinQR: require("@/assets/images/drcc/qr-code-ewarranty.png"),
      UATdaikinQR: require("@/assets/images/drcc/qr-uat-ewarranty.png"),
      daikinHeader: require("@/assets/images/drcc/Daikin_Header.png"),
      inventory: [],
      fields: [
        { key: "actions", label: "Action" },
        {
          key: "warrantyStatus",
          label: "Warranty Status",
          filterable: true,
          sortable: true,
          thStyle: { minWidth: "170px" },
        },
        { key: "itemModel", label: "Model", filterable: true, sortable: true },
        { key: "sapDo", label: "DO Number", filterable: true, sortable: true },
        { key: "barcode", label: "Barcode", filterable: true, sortable: true },
        { key: "serial", label: "Serial", filterable: true, sortable: true },
        {
          key: "dealerName",
          label: "Dealer Name",
          filterable: true,
          sortable: true,
        },
        {
          key: "dealerCode",
          label: "Dealer Code",
          filterable: true,
          sortable: true,
        },

        // { key: 'status', label: 'Status', filterable: true, sortable: true },

        // { key: 'dealer', label: 'Dealer Code', filterable: true, sortable: true },
        {
          key: "createdAt",
          label: "DO Date",
          filterable: true,
          sortable: true,
        },
      ],
      search: "",
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      model: undefined,
      barcode: undefined,
      serial: undefined,
      sapDo: undefined,
      dealerCode: "",
      selectedFilter: "DO",
      selectedField: "sapDo",
      tableLoading: true,
      toFilter: "",
      status: "",
      statusOptions: [
        // { value: '1001', text: 'Daikin Warehouse' },
        // { value: '1003', text: 'Sumitomo Warehouse' },
        "Pre",
        "On-Hold",
      ],
      type: "Set Up",
      typeOptions: ["Set Up", "Not Set Up", "Skip Scan"],
      typeDo: "Set Up",
      typeDoOptions: ["Set Up", "Not Set Up"],
      statusList: ["On-Hold", "Pre"],

      //for bulk print
      canBulkPrint: false,
      sapDoToPrint: "",
    };
  },
  mounted() {
    // console.log('DC',this.currentDealer.dealerCode);
  },
  created() {
    document.title = "View Inventory | RSP";

    //get current dealer
    this.$store.dispatch("dealer/getCurrentDealer").then((x) => {
      // console.log(x)

      if (x == null) {
        return this.getAllInventoriesDealer({
          page: this.currentPage,
          entry: this.perPage,
          serial: this.serial,
          barcode: this.barcode,
          sapDo: this.sapDo,
          // model: this.model,
          warrantyStatus: this.status,
          dealerCode: this.dealerCode,
        }).then((data) => {
          this.processMetadata(data.metadata);
          this.tableLoading = false;
        });
      }

      if (x.dealerCode !== null || x.dealerCode !== undefined) {
        this.dealerCode = x.dealerCode;
      }

      this.getAllInventoriesDealer({
        page: this.currentPage,
        entry: this.perPage,
        serial: this.serial,
        barcode: this.barcode,
        sapDo: this.sapDo,
        // model: this.model,
        warrantyStatus: this.status,
        dealerCode: this.dealerCode,
      }).then((data) => {
        this.tableLoading = false;

        this.processMetadata(data.metadata);
      });
    });
  },
  methods: {
    ...mapActions({
      getAllInventoriesDealer: "inventory/getAllInventoriesDealer",
      getCurrentDealer: "dealer/getCurrentDealer",
      updateWarrantyStatus: "warranty/updateWarrantyStatus",
    }),
    async toBulkPrint() {
      console.info("DO to print ", this.sapDoToPrint);
      this.$router.push(`/warranty/status/print/${this.sapDoToPrint}`);
      return;
    },
    async printWarrantyInstruction(id) {
      var baseURL = config.EWARRANTY_URL || "https://ewarranty.daikin.com.sg";
      const toDataURL = (url) =>
        fetch(url)
          .then((response) => response.blob())
          .then(
            (blob) =>
              new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
              })
          );
      // let header = this.daikinHeader;
      let qrcode =
        config.IS_UAT == undefined ? this.daikinQR : this.UATdaikinQR;
      let header64;
      let qrcode64;
      await toDataURL(header).then((res) => {
        header64 = res;
      });
      await toDataURL(qrcode).then((res) => {
        qrcode64 = res;
      });
      let getData = {};
      try {
        let resData = await axios.get(
          `sales-and-purchase/inventory/detail/${id}`
        );
        // console.log('res Axios',resImg)
        // console.log('resData',resData);
        getData = resData.data.data;
      } catch (error) {
        console.error(error);
      }

      // console.log("pro", getData);

      let model = getData._doc.itemModel;
      let serial = getData._doc.serial;
      let barcode = getData._doc.barcode;
      let dealerCode = getData._doc.dealerCode;
      let DONumber = getData._doc.sapDo;
      let distChan = getData._doc.industryType ? getData._doc.industryType : "";

      let listChannel = ["DL", "DR", "DS", "M1", "M2", "M3"];
      let type = listChannel.includes(distChan) ? "Route" : "Project";

      // var pdfMake = require("pdfmake/build/pdfmake.js");
      if (pdfMake.vfs == undefined) {
        // var pdfFonts = require("./customVfs.js");
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }
      let date = new Date().toISOString().substring(0, 10);

      function dateTransform(date) {
        return new Date(date).toUTCString().substring(5, 16);
      }

      // optimal width header 500
      var docDefinition = {
        pageMargins: [50, 100, 50, 50],
        header: function(currentPage, pageCount, pageSize) {
          return [
            {
              image: `${header}`,
              alignment: "center",
              width: 500,
              margin: [0, 50, 0, 0],
            },
          ];
        },
        // footer: function(currentPage, pageCount) {
        //   return [
        //     {
        //       image: `${footer64}`,
        //       width: 400,
        //       alignment: "center",
        //       margin: [0, 0, 0, 0],
        //     },
        //   ];
        // },

        content: [
          { text: `WARRANTY REGISTRATION CARD \n`, color: "#0ABDFF" },
          {
            text: `valid in Singapore only \n`,
            color: "#0ABDFF",
            fontSize: 10,
          },
          { text: `\n` },

          {
            columns: [
              {
                text: `Register online at ${baseURL} \nGo Paperless, Build a Greener Future with Daikin\n`,
              },
              {
                image: `${qrcode64}`,
                width: 75,
                height: 75,
                absolutePosition: { x: 425, y: 110 },
                // margin:[120,0,0,0],
                // fit:[75,75]
              },
            ],
          },

          { text: `\n` },
          { text: `\n` },
          {
            columns: [
              {
                text: `Model No: ${model}\n\nSerial No: ${serial}\n\nBarcode No: ${barcode}`,
              },
              {
                text: `Dealer: ${dealerCode}\n\nWarranty Type: ${type}\n\nDO No: ${DONumber}`,
              },
            ],
          },

          { text: `\n` },
          { text: `\n` },

          { text: `Important: \n`, bold: true },
          { text: `Please register within 14 days from installation date. \n` },
          {
            text: `A digital warranty certificate will be generated upon successful registration. \n`,
          },
          {
            text: `You can view your certificate through ${baseURL}/viewwarranty\n`,
          },
          {
            text: `For any warranty enquiry, please call us at 65838888 ext 2196\n`,
          },

          { text: `\n` },

          {
            text:
              "                   Thank you for choosing Daikin, the World’s Leading Air conditioning Manufacturer                ",
            color: "#ffffff",
            background: "#0AAEFF",
            alignment: "center",
          },
        ],
        defaultStyle: {
          fontSize: 11,
        },
      };

      pdfMake
        .createPdf(docDefinition)
        .download(`Daikin Warranty Registration Card.pdf`);
    },
    updateStatus(idItem, status, barcode) {
      this.updateWarrantyStatus({
        _id: idItem,
        warrantyStatus: status,
      })
        .then((data) => {
          this.$bvToast.toast(`Update item with barode : ${barcode} Success`, {
            title: "Success",
            variant: "success",
            solid: true,
          });
          // this.$router.go(-1);
        })
        .catch((e) => {
          this.$bvToast.toast(`Update Item Code Failed`, {
            title: "Fail",
            variant: "danger",
            solid: true,
          });
        });
    },
    openModal(data) {
      this.promptEdit = true;
      this.item = Object.assign(
        { ...data },
        {
          purchaseDate: this.warrantyData.purchaseDate
            ? this.warrantyData.purchaseDate
            : "",
        }
      );
      // this.item = data
      // console.log('itemss',this.item)
    },
    scanningStatus(row) {
      let barcode = row.barcode;
      let serial = row.serial;
      let status;
      if (row.skip === true) {
        barcode = "Skip Scan";
        serial = "Skip Scan";
      }

      if (row.scanStatus === "Not Scan" && row.unscanUser !== undefined) {
        barcode = "-";
        status = "Unscanned";
      }
      if (row.scanStatus === "Scan") {
        status = "Scan";
      }
      if (row.scanStatus === "Not Scan" && row.unscanUser === undefined) {
        barcode = "-";
        serial = "-";
        status = "Not Scan";
      }

      return {
        barcode,
        serial,
        status,
      };
    },
    getDealer(row) {
      let named = row;
      if (row === null || row === undefined || row === "") {
        named = { dealerName: "Not Found" };
      }
      return named;
    },
    getWarehouse(row) {
      let named = "Not Found";
      if (row === "1001") {
        named = "Daikin";
      }
      if (row === "1003") {
        named = "Sumitomo";
      }
      return named;
    },
    onPageChange(page) {
      let filter = this.getFilter();

      this.getAllInventoriesDealer({
        page: page,
        entry: this.perPage,
        dealerCode: this.dealerCode,
        ...filter,
      }).then((data) => this.processMetadata(data.metadata));
      this.tableLoading = false;
    },
    processMetadata(metadata) {
      let { totalData } = metadata;
      this.totalRows = totalData;
    },
    doFilter() {
      this.tableLoading = true;

      this.canBulkPrint = false;
      this.sapDoToPrint = "";
      let filter = this.getFilter();
      this.getAllInventoriesDealer({
        entry: this.perPage,
        dealerCode: this.dealerCode,
        ...filter,
      }).then((data) => {
        this.tableLoading = false;

        this.processMetadata(data.metadata);
        console.info("filter sap do ", filter.sapDo);
        if (filter.sapDo !== undefined) {
          this.canBulkPrint = true;
          this.sapDoToPrint = filter.sapDo;
        }
      });
    },
    toDetail(item) {
      this.$router.push(`/warranty/status/detail/${item._id}`);
    },
    dropDownMenuClick(event) {
      this.selectedFilter = event.target.text;
      this.selectedField = event.target.getAttribute("field");

      //every change selected filter, will reset type to :
      this.typeDo = "Set Up";
      this.type = "Set Up";
    },
    getFilter() {
      if (this.selectedFilter === null) {
        return {};
      }
      let filter = {};

      let tempValue = this.toFilter.trim();
      let tempFilter = this.selectedField;
      if (this.selectedFilter === "Status") {
        tempValue = this.status;
      }

      if (this.type === "Not Set Up" || this.typeDo === "Not Set Up") {
        tempValue = "Not Scan";
      }

      if (this.selectedFilter === "Serial" && this.type === "Skip Scan") {
        tempFilter = "skip";
        tempValue = true;
      }

      if (this.selectedFilter === "Barcode" && this.type === "Skip Scan") {
        tempFilter = "skip";
        tempValue = true;
      }

      filter[tempFilter] = tempValue;

      return filter;
    },
    clearFilter() {
      this.selectedField = "sapDo";
      this.selectedFilter = "DO";
      this.toFilter = "";
      this.type = "Set Up";
      this.typeDo = "Set Up";
      this.status = "";

      this.getAllInventoriesDealer({
        entry: this.perPage,
        dealerCode: this.dealerCode,
      }).then((data) => this.processMetadata(data.metadata));
    },
  },
  computed: {
    ...mapGetters({
      items: "inventory/filterInventoryDealer",
    }),
    canEdit() {
      let result = userAccess("Warranty Status", "warranty_menu");
      console.log(result);
      // console.log("Delivery Orders Sumitomo",result)
      //console.info('result permission ',result )
      if (!result) {
        return false;
      }
      return result.edit;
    },
    currentDealer() {
      //ini buat cek apakah akun yang sedang login termasuk dealer atau bukan
      return this.$store.getters["dealer/getCurrentDealers"];
    },
  },
};
</script>

<style>
.filter-responsive {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.filter-responsive button {
  margin: 0 0 0 5px;
}

@media screen and (max-width: 480px) {
  .filter-responsive {
    flex-direction: column;
    justify-content: none;
  }

  .filter-responsive button {
    margin: 5px 0 0 0;
    width: 100px;
  }
}
</style>
